import React, { useEffect, useState, useContext } from "react";
import { Link } from "gatsby";
import { Controller, useForm } from "react-hook-form";
import DataProvider from "Context/DataContext";
import { ServicesData } from "./servicesData";
import Select from "react-select";
import { useTranslatorRegister } from "hooks/Forms/useTranslatorRegister";
import Loader from "components/loader";
import Modal from "components/popup";
import { useLocation } from "@reach/router";
import { ExpertField } from "./expertiseField";

function SecondStepAccount({ currentStep, setCurrentStep, data }) {
  const { firstStepData, cvUpload } = useContext(DataProvider);
  const { nativeLangData, setNativeLangData } = useContext(DataProvider);
  const { expertiseData, setExpertiseData } = useContext(DataProvider);
  const { originalLangData, setOriginalLangData } = useContext(DataProvider);
  const { targetLangData, setTargetLangData } = useContext(DataProvider);
  const { servicesData, setServicesData } = useContext(DataProvider);
  const location = useLocation();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      native_language: nativeLangData,
      expertise_field: expertiseData,
      source_language: originalLangData,
      target_language: targetLangData,
      services: servicesData,
    },
  });

  const { status, mutateAsync } = useTranslatorRegister();
  const [open, setOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalErrorMessage, setModalErrorMessage] = useState("");

  const refreshPage = () => {
    window.location.reload();
  };

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("first_name", firstStepData.first_name);
    formData.append("last_name", firstStepData.last_name);
    formData.append("uploading_file", cvUpload);
    formData.append("username", firstStepData.username);
    formData.append("email", firstStepData.email);
    formData.append("password", firstStepData.password);
    formData.append("phone_number", firstStepData.phone_number);
    formData.append("country", firstStepData.country);
    formData.append("native_language", nativeLangData);
    formData.append("field_of_expertise", expertiseData);
    formData.append("source_language", originalLangData);
    formData.append("target_language", targetLangData);
    formData.append("service", servicesData);
    formData.append("pagelink", location.href);

    try {
      const response = await mutateAsync(formData);
      if (response.data.success === 1) {
        setModalMessage(response.data.message);
        setOpen(true);
      } else {
        setModalMessage(response.data.message);
        setOpen(true);
      }
    } catch (error) {
      if (error?.response?.status !== 200) {
        setOpen(true);
        const errorMessage = error.response.data.message.email[0];
        setModalErrorMessage(errorMessage);
      }
      console.error("Submission error: ", error);
    }
  };

  const filterOptions = (options, exclude) =>
    options.filter(({ value }) => !exclude.includes(value));

  const languageOptions = data?.data?.map(({ source_language }) => ({
    value: source_language,
    label: source_language,
  }));

  const sourceLanguageOptions = filterOptions(
    languageOptions,
    targetLangData || []
  );

  const targetLanguageOptions = filterOptions(
    languageOptions,
    originalLangData || []
  );

  return (
    <div>
      <div className="rounded-lg bg-black py-5 px-12 h-full mx-4 flex flex-col justify-center md:mx-auto max-w-5xl font-primary text-white relative">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal
            open={open}
            setOpen={setOpen}
            message={modalMessage}
            errorMessage={modalErrorMessage}
            refreshPage={refreshPage}
          />
          <div className="grid grid-cols-1 lg:grid-cols-2 relative lg:gap-x-16">
            <div className="mb-6">
              <p className="text-24">Native Language</p>
              <p className="text-base text-[#4f4f4f] mb-2">
                You can select up to 2 native languages
              </p>

              <Controller
                name="native_language"
                control={control}
                rules={{ required: "Native language is required" }}
                render={({ field }) => (
                  <Select
                    {...field}
                    className="bg-white font-primary text-xs mb-2 focus:ring-0 rounded-md text-black w-full lg:w-[416px] language"
                    id="native_language"
                    options={languageOptions}
                    isMulti
                    onChange={(selectedOptions) => {
                      field.onChange(selectedOptions);
                      setNativeLangData(
                        selectedOptions
                          ? selectedOptions.map((option) => option.value)
                          : []
                      );
                    }}
                    value={nativeLangData?.map((selectedVal) => ({
                      value: selectedVal,
                      label: selectedVal,
                    }))}
                  />
                )}
              />
              {errors.native_language && (
                <p className="text-[#ff0000] text-sm">
                  {errors.native_language.message}
                </p>
              )}
            </div>
            <div className="mb-6">
              <p className="text-24">Expertise field</p>
              <p className="text-base text-[#4f4f4f] mb-2">
                You can select up to 3 expertise fields
              </p>

              <Controller
                name="expertise_field"
                control={control}
                rules={{ required: "At least one expertise field is required" }}
                render={({ field }) => (
                  <Select
                    {...field}
                    className="bg-white font-primary text-lg mb- text-black w-full lg:w-[416px]"
                    isMulti
                    options={ExpertField.map(({ fieldsName }) => ({
                      value: fieldsName,
                      label: fieldsName,
                    }))}
                    onChange={(selectedOptions) => {
                      field.onChange(selectedOptions);
                      setExpertiseData(
                        selectedOptions
                          ? selectedOptions.map((option) => option.value)
                          : []
                      );
                    }}
                    value={expertiseData?.map((expVal) => ({
                      value: expVal,
                      label: expVal,
                    }))}
                  />
                )}
              />
              {errors.expertise_field && (
                <p className="text-[#ff0000] text-sm">
                  {errors.expertise_field.message}
                </p>
              )}
            </div>
            <div className="mb-10">
              <p className="text-24">Source Language</p>
              <p className="text-base text-[#4f4f4f] mb-2">
                You can select many language pairs. Just click on "Add" after
                selecting each language pair
              </p>

              <Controller
                name="source_language"
                control={control}
                rules={{ required: "Source language is required" }}
                render={({ field }) => (
                  <Select
                    {...field}
                    placeholder="Select source language*"
                    className="bg-white font-primary text-lg mb-2 text-black w-full lg:w-[416px]"
                    options={sourceLanguageOptions}
                    isMulti
                    onChange={(selectedOptions) => {
                      field.onChange(selectedOptions);
                      setOriginalLangData(
                        selectedOptions
                          ? selectedOptions.map((option) => option.value)
                          : []
                      );
                    }}
                    value={originalLangData?.map((orgVal) => ({
                      value: orgVal,
                      label: orgVal,
                    }))}
                  />
                )}
              />
              {errors.source_language && (
                <p className="text-[#ff0000] text-sm">
                  {errors.source_language.message}
                </p>
              )}
            </div>
            {(originalLangData || targetLangData.length > 0) && (
              <div className="mb-10">
                <p className="text-24">Target Language:</p>
                <p className="text-base text-[#4f4f4f] mb-2 invisible">
                  You can select many language pairs. Just click on "Add" after
                  selecting each language pair
                </p>

                <Controller
                  name="target_language"
                  control={control}
                  rules={{
                    required: "At least one target language is required",
                  }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      placeholder="Select target language*"
                      className="bg-white font-primary text-xs mb-2 rounded-md text-black w-full lg:w-[416px]"
                      isMulti
                      options={targetLanguageOptions}
                      onChange={(selectedOptions) => {
                        field.onChange(selectedOptions);
                        setTargetLangData(
                          selectedOptions
                            ? selectedOptions.map((option) => option.value)
                            : []
                        );
                      }}
                      value={targetLangData?.map((orgVal) => ({
                        value: orgVal,
                        label: orgVal,
                      }))}
                    />
                  )}
                />
                {errors.target_language && (
                  <p className="text-[#ff0000] text-sm">
                    {errors.target_language.message}
                  </p>
                )}
              </div>
            )}
            <div className="mb-6">
              <p className="text-24">Service</p>
              <p className="text-base text-[#4f4f4f] mb-2">
                You can select up to 3 services
              </p>

              <Controller
                name="services"
                control={control}
                rules={{ required: "At least one service is required" }}
                render={({ field }) => (
                  <Select
                    {...field}
                    className="bg-white font-primary text-lg mb-2  text-black w-full lg:w-[416px]"
                    isMulti
                    options={ServicesData.map(({ serviceName }) => ({
                      value: serviceName,
                      label: serviceName,
                    }))}
                    onChange={(serviceOptions) => {
                      field.onChange(serviceOptions);
                      setServicesData(
                        serviceOptions
                          ? serviceOptions.map((option) => option.value)
                          : []
                      );
                    }}
                    value={servicesData?.map((expVal) => ({
                      value: expVal,
                      label: expVal,
                    }))}
                  />
                )}
              />
              {errors.services && (
                <p className="text-[#ff0000] text-sm">
                  {errors.services.message}
                </p>
              )}
            </div>
          </div>
          <div className="flex w-full items-center justify-center my-6">
            <button
              className="bg-lightBlue text-white w-full md:max-w-max rounded py-2 px-12"
              disabled={status === "loading"}
            >
              {status === "loading" ? <Loader /> : "Submit"}
            </button>
          </div>

          <p className="text-white mt-12 mb-3">
            If you encounter any problems signing up, Please{" "}
            <a
              href="https://www.tomedes.com/contactus.php"
              target="_blank"
              className="text-lightBlue"
            >
              Contact
            </a>
            &nbsp;us or&nbsp;
            <a
              href="https://server.iad.liveperson.net/hc/4900916/?cmd=file%C2%A0file=visitorWantsToChat%C2%A0site=4900916%C2%A0byhref=1%C2%A0imageUrl=https://server.iad.liveperson.net/hcp/Gallery/ChatButton-Gallery/English/General/1a/"
              className="text-lightBlue"
            >
              chat now
            </a>
            &nbsp;with our live agents.
          </p>
        </form>
        <button
          onClick={() => {
            setCurrentStep(1);
          }}
          className="text-white absolute bottom-[-40px] left-0 hover:text-lightBlue"
        >
          Return to first step
        </button>
      </div>
    </div>
  );
}

export default SecondStepAccount;
