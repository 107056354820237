import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { CountryArray } from "utils/constants";
import DataProvider from "Context/DataContext";
import {
  errorMessage,
  errorMessageEmail,
  patternEmail,
} from "utils/form-validation";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";

function FirstStepAccount({ setCurrentStep }) {
  const { firstStepData, setFirstStepData, setCvUpload, cvUpload } =
    useContext(DataProvider);
  const [selectedCountry, setSelectedCountry] = useState(
    firstStepData.country || ""
  );
  const [phoneCountryCode, setPhoneCountryCode] = useState(
    firstStepData.phone_number ? firstStepData.phone_number.substring(0, 4) : ""
  );
  const [phoneValue, setPhoneValue] = useState(
    firstStepData.phone_number || ""
  );
  const {
    register,
    handleSubmit,
    reset,
    setError,
    control,
    formState: { errors },
    clearErrors,
    watch,
    setValue,
  } = useForm();
  const [getError, setGetError] = useState(false);
  const [userError, setUserError] = useState(false);
  const email = watch("email", firstStepData.email);
  const username = watch("username", firstStepData.username);
  const confirmEmail = watch("confirm_email", firstStepData.confirm_email);
  const password = watch("password", firstStepData.password);
  const confirmPassword = watch(
    "confirm_password",
    firstStepData.confirm_password
  );
  const countryCodeToNameMap = CountryArray.reduce((acc, country) => {
    acc[country.dial_code] = country.name;
    return acc;
  }, {});

  useEffect(() => {
    if (confirmEmail && confirmEmail !== email) {
      setError("confirm_email", {
        type: "manual",
        message: "Email and confirm email should match!",
      });
    } else {
      clearErrors("confirm_email");
    }
  }, [email, confirmEmail, setError, clearErrors]);

  useEffect(() => {
    if (confirmPassword && confirmPassword !== password) {
      setError("confirm_password", {
        type: "manual",
        message: "Password and confirm password should match!",
      });
    } else {
      clearErrors("confirm_password");
    }
  }, [password, confirmPassword, setError, clearErrors]);

  useEffect(() => {
    const checkEmailExists = async (email) => {
      const emailPattern = new RegExp(patternEmail);
      if (email && emailPattern.test(email)) {
        try {
          const response = await axios.get(
            `https://vendordev.tomedes.com/api/vendor-register-validation`,
            { params: { email } }
          );
          if (response.data.success === 1) {
            setGetError("");
          }
        } catch (error) {
          console.log("Error checking email:", error);
          if (error?.response?.status !== 200) {
            setGetError(error.response.data.message.email[0]);
          }
        }
      } else {
        setGetError("");
      }
    };

    checkEmailExists(email);
  }, [email, setError, clearErrors]);

  useEffect(() => {
    const checkUsernameExists = async (username) => {
      const usernamePattern = /^[a-zA-Z][a-zA-Z0-9]*$/;
      if (username && usernamePattern.test(username)) {
        try {
          const response = await axios.get(
            `https://vendordev.tomedes.com/api/vendor-register-validation`,
            { params: { username } }
          );
          if (response.data.success === 1) {
            setUserError("");
          }
        } catch (error) {
          console.error("Error checking username:", error);
          if (error?.response?.status !== 200) {
            setUserError(error.response.data.message.username[0]);
          }
        }
      } else {
        setUserError("");
      }
    };

    checkUsernameExists(username);
  }, [username, setError, clearErrors]);
  const onSubmit = (data) => {
    let hasError = false;

    if (data.password !== data.confirm_password) {
      setError("confirm_password", {
        type: "manual",
        message: "Password and confirm password should match!",
      });
      hasError = true;
    }

    if (data.email !== data.confirm_email) {
      setError("confirm_email", {
        type: "manual",
        message: "Email and confirm email should match!",
      });
      hasError = true;
    }

    if (getError || userError || hasError) {
      return;
    }

    data.country = selectedCountry;
    setFirstStepData(data);
    setCurrentStep(2);
  };

  const handleUploadCv = (e) => {
    const files = e.target.files[0];
    setCvUpload(files);
  };

  const handlePhoneChange = (value) => {
    setPhoneValue(value);
    const dialCode = `+${value}`;
    const country = CountryArray.find(
      (country) => country.dial_code === dialCode
    );
    if (country) {
      setSelectedCountry(country.name);
      setValue("country", country.name, { shouldValidate: true });
    }
  };

  const handleCountryChange = (selectedOption) => {
    const selectedCountryName = selectedOption ? selectedOption.value : "";
    setSelectedCountry(selectedCountryName);
    if (selectedOption) {
      const country = CountryArray.find(
        (country) => country.name === selectedCountryName
      );
      if (country) {
        setPhoneValue(country.dial_code.replace("+", ""));
      }
    }
  };

  const countryOptions = CountryArray.map((country) => ({
    value: country.name,
    label: country.name,
  }));

  return (
    <div>
      <div className="rounded-lg bg-black py-5 px-12 h-full mx-4 md:w-10/12 lg:w-9/12 flex flex-col justify-center md:mx-auto max-w-[800px] font-primary">
        <p className="text-center text-white text-xl sm:px-4">
          Please enter the following information.Your information will be kept
          completely{" "}
          <a
            href="https://www.tomedes.com/privacy"
            target="_blank"
            className="text-lightBlue font-semibold"
          >
            confidential.
          </a>
        </p>
        <p className="text-center text-white py-6 font-light">
          Fields marked with an <span className="text-red">*</span> are
          required.
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-16 gap-y-6 mb-2 items-start">
            <div className="w-full flex flex-col">
              <input
                type="text"
                placeholder="First Name*"
                className="rounded pl-3 text-black py-1 w-full"
                id="first_name"
                name="first_name"
                defaultValue={firstStepData.first_name}
                {...register("first_name", { required: true })}
              />
              {errors.first_name && (
                <span className="text-red text-xs mt-[2px]">
                  {errorMessage}
                </span>
              )}
            </div>
            <div className="flex flex-col">
              <input
                type="text"
                placeholder="Last Name*"
                className="rounded pl-3 text-black py-1"
                id="last_name"
                name="last_name"
                defaultValue={firstStepData.last_name}
                {...register("last_name", { required: true })}
              />
              {errors.last_name && (
                <span className="text-red text-xs mt-[2px]">
                  {errorMessage}
                </span>
              )}
            </div>
            <div className="w-full flex flex-col">
              <input
                type="email"
                placeholder="E-Mail*"
                className="rounded pl-3 text-black py-1 w-full"
                id="email"
                name="email"
                defaultValue={firstStepData.email}
                {...register("email", {
                  required: true,
                  pattern: patternEmail,
                })}
              />
              {errors.email && (
                <span className="text-red text-xs mt-[2px]">
                  {errorMessageEmail}
                </span>
              )}
              {getError && (
                <span className="text-red text-xs mt-[2px]">{getError}</span>
              )}
            </div>
            <div className="w-full flex flex-col">
              <input
                type="email"
                placeholder="Confirm E-Mail*"
                className="rounded pl-3 text-black py-1 w-full"
                id="confirm_email"
                name="confirm_email"
                defaultValue={firstStepData.confirm_email}
                {...register("confirm_email", {
                  required: true,
                  pattern: patternEmail,
                })}
              />
              {errors.confirm_email && (
                <span className="text-red text-xs mt-[2px]">
                  {errors.confirm_email.message || errorMessageEmail}
                </span>
              )}
            </div>
            <div className="w-full flex flex-col">
              <Controller
                name="password"
                control={control}
                defaultValue={firstStepData.password || ""}
                rules={{
                  required: "Password is required",
                  pattern: {
                    value: /^.{8,}$/,
                    message: "Password must be at least 8 characters long",
                  },
                }}
                render={({ field }) => (
                  <>
                    <input
                      type="password"
                      placeholder="Password*"
                      className="rounded pl-3 text-black py-1 w-full"
                      id="password"
                      name="password"
                      {...field}
                    />
                    {errors.password && (
                      <span className="text-red text-xs mt-[2px]">
                        {errors.password.message}
                      </span>
                    )}
                  </>
                )}
              />
            </div>
            <div className="w-full flex flex-col">
              <input
                type="password"
                placeholder="Confirm Password*"
                className="rounded pl-3 text-black py-1 w-full"
                id="confirm_password"
                name="confirm_password"
                defaultValue={firstStepData.confirm_password}
                {...register("confirm_password", { required: true })}
              />
              {errors.confirm_password && (
                <span className="text-red text-xs mt-[2px]">
                  {errors.confirm_password.message || errorMessage}
                </span>
              )}
            </div>

            <div className="w-full min-w-[300px] lg:w-[325px] flex flex-col">
              <Controller
                name="country"
                control={control}
                defaultValue={selectedCountry}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={countryOptions}
                    placeholder="Select Country*"
                    value={countryOptions.find(
                      (option) => option.value === selectedCountry
                    )}
                    onChange={(selectedOption) => {
                      handleCountryChange(selectedOption);

                      field.onChange(
                        selectedOption ? selectedOption.value : ""
                      );
                    }}
                    className="rounded min-w-[300px] lg:w-[325px] text-black py-1"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        borderColor: errors.country
                          ? "red"
                          : provided.borderColor,
                      }),
                    }}
                    isClearable
                  />
                )}
              />
              {errors.country && (
                <span className="text-red text-xs mt-[2px]">
                  {errorMessage}
                </span>
              )}
            </div>
            <div className="w-full min-w-[300px] lg:w-[325px]">
              <Controller
                name="phone_number"
                control={control}
                defaultValue={firstStepData.phone_number || ""}
                rules={{
                  required: "Phone number is required",
                  validate: (value) => {
                    if (value.length < 10 || value.length > 15) {
                      return "Phone number must be between 10 and 15 digits";
                    }
                    return true;
                  },
                }}
                render={({ field }) => (
                  <div className="phone-input flex flex-col">
                    <PhoneInput
                      country={"us"}
                      value={phoneValue}
                      placeholder="Phone Number*"
                      onChange={(value) => {
                        field.onChange("+" + value);
                        handlePhoneChange(value);
                      }}
                      inputProps={{
                        name: "phone_number",
                        id: "phone_number_input",
                      }}
                      excludeCountries={[
                        "ru",
                        "cu",
                        "ir",
                        "mm",
                        "sd",
                        "sy",
                        "kp",
                        "by",
                        "zw",
                        "ua",
                        "lb",
                        "iq",
                        "pk",
                        "af",
                      ]}
                      className="border rounded-md border-[#BBBBBB] text-black h-[45px]"
                    />
                    {errors.phone_number && (
                      <span className="text-red text-xs mt-[2px]">
                        {errors.phone_number.message}
                      </span>
                    )}
                  </div>
                )}
              />
            </div>
            <div className="w-full flex flex-col">
              <input
                type="text"
                placeholder="Desired username*"
                className="rounded pl-3 text-black py-1 w-full"
                id="username"
                name="username"
                defaultValue={firstStepData.username}
                {...register("username", {
                  required: "Username is required",
                  pattern: {
                    value: /^[a-zA-Z][a-zA-Z0-9]*$/,
                    message:
                      "Username must start with a letter and contain only letters and numbers",
                  },
                })}
              />
              {errors.username && (
                <span className="text-red text-xs mt-[2px]">
                  {errors.username.message || errorMessage}
                </span>
              )}
              {userError && (
                <span className="text-red text-xs mt-[2px]">{userError}</span>
              )}
            </div>
            <div className="w-full relative flex flex-col">
              <p className="text-xs text-white absolute top-[-16px]">
                Upload your CV
              </p>
              <input
                type="file"
                placeholder=""
                className="rounded pl-3 text-black py-1 bg-white"
                id="cv_file"
                name="cv_file"
                defaultValue={cvUpload}
                {...register("cv_file", {
                  onChange: handleUploadCv,
                })}
              />
            </div>
          </div>
          <div className=" flex flex-col py-4 relative">
            <button
              className="bg-lightBlue text-white max-w-max rounded py-1 px-5 absolute right-0 bottom-0"
              // onClick={() => setCurrentStep(2)}
            >
              Continue
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default FirstStepAccount;
